import logo from './logo.svg';
import styles from './App.css';
import React, { useState, useCallback, useEffect } from 'react';
import tes from "./test.html"

function App() {
  const [message, setMessage] = React.useState()
  React.useEffect(() => {
    var gateway = ("wss://webs.ryutokojou.workers.dev/ws")
    // var gateway = ("ws://127.0.0.1:8787/ws")
    var websocket
    try {
      websocket = new WebSocket(gateway);
      document.getElementById("websocket-dev").innerHTML = "success"
    } catch (error) {
      document.getElementById("websocket-dev").innerHTML = error
    }
    // window.addEventListener('load', onLoad);
    function initWebSocket() {
      console.log('Trying to open a WebSocket connection...');
      try {
        websocket = new WebSocket(gateway);
        document.getElementById("websocket-dev").innerHTML = "success2"
      } catch (error) {
        document.getElementById("websocket-dev").innerHTML = error
      }
      websocket.onopen = onOpen;
      websocket.onclose = onClose;
      websocket.onmessage = onMessage; // <-- add this line
    }
    function onOpen(event) {
      console.log('Connection opened');
      // websocket.send(JSON.stringify({type:"get"}))
      websocket.send(JSON.stringify({ type: "all", value: "SELECT * FROM c" }))
    }
    function onClose(event) {
      console.log('Connection closed');
      setTimeout(initWebSocket, 2000);
      websocket = new WebSocket(gateway);
    }
    function onMessage(event) {
      // console.log(event.data)
      // if(JSON.parse(event.data).data == "count"){
      //   setMessage(JSON.parse(event.data).value)
      // }
      // console.log(JSON.parse(event.data).data)
      try {
        switch (JSON.parse(event.data).data) {
          case "db":
            // console.log(JSON.parse(event.data).value)
            break;
          case "all":
            var cont = JSON.parse(event.data)
            // console.log(JSON.parse(cont.value).results[0].count)
            // setMessage(JSON.parse(cont.value).results[0].count)
            document.getElementById("count_p").innerHTML = JSON.parse(cont.value).results[0].count
            document.getElementById("websocket-dev").innerHTML = JSON.parse(cont.value)
            setTimeout(() => {
              websocket.send(JSON.stringify({ type: "all", value: "SELECT * FROM c" }))
            }, 1000);
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error)
      }
    }
    initWebSocket();

    fetch("https://webs.ryutokojou.workers.dev/options", {method: 'GET',mode:"cors"})
        .then(res => res.json())
        .then(data => {
            for(var i in data){
              (function(n){
                console.log(data[n].option_name);
                console.log(data[n].options)
                document.getElementById(data[n].option_name).hidden = data[n].options
              })(i)
            }
        })

  })
  const [isVisible, setIsVisible] = useState(true);
  var result;
  var check = 1;
  const onClickSwitchDisplay = () => {
    setIsVisible(!isVisible);
  };
  if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
    result =
      <div style={{ overflow: "scroll", backgroundImage: "url(./background.jpg)", backgroundSize: "100%", width: "100%", height: "1000px", maxHeight: "100%", backgroundAttachment: "fixed" }}>
        <div className="top">
          <div className="hamburger-menu">
            <input type="checkbox" id="menu-btn-check"></input>
            <label for="menu-btn-check" className="menu-btn" onClick={onClickSwitchDisplay} id='aaaaaa'><span></span></label>
            <div className="label_li" style={{ display: isVisible ? 'none' : '' }} >
              <li id='y'><a href='#rule'>待ち人数</a></li>
              <details>
                <summary className="summary1">ルール</summary>
                <div className="label_li">
                  <li><a href='#rule0'>Eカード</a></li>
                  <li><a href='#rule1'>ドキドキ!ダーツバトル!</a></li>
                  <li><a href='#rule2'>ビリヤード</a></li>
                  <li><a href='#rule3'>ポーカー</a></li>
                </div>
              </details>
            </div>
          </div>
          <a href="/sm" className="aaa">遊戯黙示録タイチ</a>
          {/* <a href="/sm" className="aaa">{message}</a> */}
        </div>

        <div>
          <img src="./遊戯黙示録.png" className="img_a"></img>
        </div>
        <div style={{ margin: "30px", marginTop: "-30px" }}>
          <div style={{ background: "#FFFFFF" }}>
            <p>22番教室で開催されるトランプゲームを筆頭に様々なゲームを取り入
              れたファンタジースタジオ。スリル感・緊張感・高揚感をその肌で体感
              せよ。※全年齢が対象に作り上げております。</p>
          </div>
        </div>
        <div style={{ margin: "30px", marginTop: "50px" }} id='rule'>
          <div style={{ background: "#FFFFFF" }}>
            <h2>待ち人数</h2>
            <p>現在の待ち人数-- <span id='count_p'></span></p>
            <span id='websocket-dev' hidden></span>
          </div>
        </div>
        <div style={{ margin: "30px", marginTop: "50px", background:"#FFFFFF" }}>
          <h3>間取り</h3>
          <img src='./教室間取り.png' style={{width:"100%",height:"100%"}}></img>
        </div>
        <div style={{ margin: "30px", marginTop: "50px" }} id='rule0'>
          <img src='./Eカード.jpg' style={{width:"100%",height:"100%"}}></img>
        </div>
        <div style={{ margin: "30px", background: "#FFFFFF", marginTop: "50px" }} id='rule1'>
          <img src='./darts.jpg' style={{width:"100%",height:"100%"}}></img>
        </div>
        <div style={{ margin: "30px", background: "#FFFFFF", marginTop: "50px" }} id='rule2'>
          <img src='./S__21037077.jpg' style={{width:"100%",height:"100%"}}></img>
        </div>
        <div style={{ margin: "30px", background: "#FFFFFF", marginTop: "50px" }} id='rule3'>
          <img src='./ポーカールール修正版_page-0001.jpg' style={{width:"100%",height:"100%"}}></img>
          <img src='./ポーカールール修正版_page-0002.jpg' style={{width:"100%",height:"100%"}}></img>
        </div>
        <div style={{backgroundColor:"#FFFFFF"}}>
          <h2>maked by 古城隆人</h2>
          <p style={{margin:"50px"}}></p>
          <p>work in cloudflare pages</p>
        </div>
      </div>
  } else {
    result = <div>
      <div className="top">
        <p href="file:///C:/Users/kojor_fyn5sjo/Music/html/homepage.html">遊戯黙示録タイチ</p>
        <p>予約</p>
        <details id='aaaaaa'>
          <summary className="summary">ルール</summary>
          <div className="label_li2">
            <li><a href="https://www.poker-navi.jp/rule">トランプ類</a></li>
            <li><a href="https://www.billiards-cues.jp/rule/">ビリヤード</a></li>
            <li><a href="https://www.dartslive.com/jp/beginner/">ダーツ</a></li>
          </div>
        </details>
      </div>
      <div>
        <img src="./S__5193777.jpg" className="img_a"></img>
      </div>
    </div>
  }
  return (
    result
  );
}

export default App;
